import apiGuideComponents from '/themes/gatsby-theme-eg-mdx/src/components/Guide/apiGuideComponents';
// Use src instead of transpiled path, since gatsby gona compile it
import GraphqlPlayground from '/themes/gatsby-theme-eg-graphql-playground/src/components/GraphqlPlayground';
import ChangeLog from '/themes/gatsby-theme-eg-changelog/src/components/ChangeLog';

export default Object.assign(apiGuideComponents, {
    // Add custom components here for usage in mdx markup
    GraphqlPlayground,
    ChangeLog
});
