/* eslint-disable no-undefined */
// TODO: config driven
const appName = "DevPortalDocs";

function triggerEdapEvent(eventName = "", eventData = {}, options = {}) {
    /* istanbul ignore if */
    if (typeof window === undefined || window.edap === undefined) {
        return;
    }
    if (eventData.pagename) {
        eventData.pagename = `${appName}:${eventData.pagename}`;
    }
    window.edap.push(edapInstance => {
        edapInstance.trigger(eventName, eventData, options);
    });
}

function triggerEdapPageView(pageName = "") {
    /* istanbul ignore if */
    if (typeof window === undefined || window.edap === undefined) {
        return;
    }
    window.analyticsdatalayer.pagename = `${appName}:${pageName}`;
    window.edap.push(edapInstance => {
        edapInstance.trigger("pageview");
    });
}

const eventCategories = {
    PRIMARY_NAVIGATION: "Primary navigation",
    SECONDARY_NAVIGATION: "Secondary navigation",
    CONTENT_NAVIGATION: "Content navigation",
    COPY_CODE: "Copy code"
};

export { triggerEdapPageView, triggerEdapEvent, eventCategories };
