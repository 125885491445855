import React from "react";
import PropTypes from "prop-types";
import "./styles.less";
import "./prism-custom.css";

const Layout = props => {
    return (
        <>
            <main>{props.children}</main>
            {/* <link
        href="//csvcus.homeaway.com/rsrcs-crs/web-fonts/2.6.0/webfonts-eg.min.css"
        rel="stylesheet"
        type="text/css"
      />
      <link
        rel="stylesheet"
        href="//csvcus.homeaway.com/rsrcs/baseline/5.3.1/stylesheets/baseline-eg.min.css"
      />
      <link
        rel="stylesheet"
        href="//csvcus.homeaway.com/rsrcs/baseline/5.3.1/stylesheets/eg.min.css"
      /> */}
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
