function isSectionInView(section, offset) {
    return window.scrollY >= section.offsetTop - offset;
}

function isBottomOfView() {
    const docHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );
    const windowBottom = window.innerHeight + window.scrollY;
    return windowBottom >= docHeight;
}

function determineActiveSection(sections) {
    let activeSection;
    const sectionsCount = sections.length - 1;
    for (let i = sectionsCount; i > -1; i--) {
        const section = sections[i];
        if (isSectionInView(section, 145)) {
            activeSection = i;
            break;
        }
        if (isBottomOfView()) {
            activeSection = sectionsCount;
            break;
        }
    }
    return activeSection;
}

export { isSectionInView, determineActiveSection, isBottomOfView };
