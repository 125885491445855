/* istanbul ignore file */
// The following files are impacted by an issue with an
// asynchronous call that updates the state.
// See this github issue + discussion.
// https://github.com/testing-library/react-testing-library/issues/281
// Once 16.9 is released these should be revisited

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "codemirror/lib/codemirror.css";
import "./HeadersEditor.less";
import editorOptions from "./EditorOptions.json";
import CancelIcon from "./CancelIcon";

function authKeyToLowerCase(headersObject) {
    const newHeadersObject = {};
    Object.keys(headersObject).map(function(key) {
        newHeadersObject[
            key.toLowerCase() == "authorization" ? key.toLowerCase() : key
        ] = headersObject[key];
    });
    return newHeadersObject;
}

function RequestHeadersEditor(props) {
    const { editorTitle, headers, onUpdate, onClose } = props;

    const [Editor, setEditor] = useState(null);
    useEffect(() => {
        async function initializeEditor() {
            await import("codemirror/mode/javascript/javascript");
            const component = await import("react-codemirror");
            setEditor(component);
        }

        initializeEditor();
    }, []);

    const onChange = newHeaders => {
        try {
            const newHeadersObject = authKeyToLowerCase(JSON.parse(newHeaders));
            onUpdate(newHeadersObject);
        } catch (err) {
            // when the updated cannot be parsed as JSON, do nothing
        }
    };

    return (
        <div className="graphql-api-explorer__headers-editor">
            <span className="graphql-api-explorer__headers-editor-title">
                {editorTitle}
            </span>
            <button
                className="graphql-api-explorer__headers-editor-close-button"
                onClick={onClose}
            >
                <CancelIcon alt="close headers editor" />
            </button>
            {Editor ? (
                <Editor.default
                    mode="graphql-variables"
                    value={JSON.stringify(headers, null, 2)}
                    onChange={onChange}
                    options={editorOptions}
                />
            ) : (
                <div />
            )}
        </div>
    );
}

RequestHeadersEditor.defaultProps = {
    headers: {},
    editorTitle: "HTTP Headers"
};

RequestHeadersEditor.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    headers: PropTypes.object,
    editorTitle: PropTypes.string
};

export default RequestHeadersEditor;
