import React, { useState, useEffect } from "react";
import NavBar from "@homeaway/react-navbar";
import { SVGIcon } from "@homeaway/react-svg";
import { MENU__24, CLOSE__24 } from "@homeaway/svg-defs";
import PropTypes from "prop-types";
import NavigationPropTypes from "../NavigationPropTypes";
import { triggerEdapEvent, eventCategories } from "../../../util/edap";
import "./styles.less";

function PrimaryNavigation(props) {
    const { navLinks, activeIndex = 0 } = props;

    const useLogo = {
        href: "#getting-started",
        image:
            "//csvcus.homeaway.com/rsrcs/cdn-logos/4.3.2/sitename/escapia/web/logo.png"
    };
    // manage the active navigation
    const [activeNavIndex, setActiveNavIndex] = useState(activeIndex);

    const updateActiveIndex = (_event, newActiveSubheaderIndex) => {
        setActiveNavIndex(newActiveSubheaderIndex);
    };

    const handleNavLinkClick = (e, newActiveSubheaderIndex) => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.PRIMARY_NAVIGATION,
            eventaction: `${navLinks[newActiveSubheaderIndex].label} link clicked`
        });
        updateActiveIndex(e, newActiveSubheaderIndex);
    };

    // Show the menu in smaller screen sizes
    const [showVerticalNav, setShowVerticalNav] = useState(false);

    const toggleVerticalNav = () => {
        setShowVerticalNav(!showVerticalNav);
    };

    const handleVerticalNavLinkClick = e => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.PRIMARY_NAVIGATION,
            eventaction: `${e.currentTarget.name} link clicked`
        });
        toggleVerticalNav();
    };

    useEffect(() => {
        // close vertical menu when screen has been resized
        const mql = window.matchMedia("(min-width: 991px)");
        const onResize = () => {
            if (mql.matches) {
                setShowVerticalNav(false);
            }
        };
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const verticalNavigationLinks = navLinks.map(navLink => {
        return (
            <a
                key={`vertical-nav-${navLink.label}`}
                href={navLink.node}
                name={navLink.label}
                onClick={handleVerticalNavLinkClick}
                data-testid={`vertical-navigation__${navLink.label}-link`}
            >
                <span className="primary-navigation__link-label">
                    {navLink.label}
                </span>
            </a>
        );
    });

    return (
        <div className="primary-navigation">
            <div className="primary-navigation__wrapper">
                <div className="primary-navigation__vertical-navigation">
                    <div
                        className={`primary-navigation__vertical-navigation-list ${
                            showVerticalNav ? "" : "hide"
                        }`}
                        data-testid="vertical-nav-list"
                    >
                        <div className="primary-navigation__vertical-navigation-list-content">
                            <button
                                className="primary-navigation__close-icon"
                                onClick={toggleVerticalNav}
                                data-testid="vertical-navigation-close-btn"
                            >
                                <SVGIcon markup={CLOSE__24} />
                            </button>
                            <div className="primary-navigation__vertical-navigation-list-items">
                                {verticalNavigationLinks}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="primary-navigation__container">
                    <button
                        className="primary-navigation__vertical-navigation-icon"
                        onClick={toggleVerticalNav}
                        data-testid="vertical-navigation-toggle-btn"
                    >
                        <SVGIcon markup={MENU__24} />
                    </button>
                    <div
                        className="primary-navigation__nav-content"
                        data-testid="primary-nav-content"
                    >
                        <NavBar
                            activeIndex={activeNavIndex}
                            searchEnabled={false}
                            siteTitle={"API Welcome Documentation"}
                            logo={useLogo}
                            links={navLinks}
                            onLinkClick={handleNavLinkClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

PrimaryNavigation.propTypes = {
    activeIndex: PropTypes.number,
    navLinks: PropTypes.arrayOf(NavigationPropTypes.NavLinks).isRequired,
    logo: PropTypes.object
};

export default PrimaryNavigation;
