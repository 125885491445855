import { useState, useEffect } from "react";
import Cookies from "js-cookie";

const useStateWithCookies = (defaultValue, cookiesKey) => {
    const [value, setValue] = useState(
        Cookies.getJSON(cookiesKey) || defaultValue // it seems this doesn't work as expected. Cookies.getJSON(cookiesKey) returns  { '[object Object]': '' } for empty string
    );
    useEffect(() => {
        Cookies.set(cookiesKey, JSON.stringify(value));
    }, [value]);
    return [value, setValue];
};

const useStateCookiesExt = (defaultValue, cookiesKey, shouldUseCookies) => {
    if (shouldUseCookies && typeof window !== "undefined" && document.cookie) {
        return useStateWithCookies(defaultValue, cookiesKey);
    }
    return useState(defaultValue);
};

export { useStateWithCookies, useStateCookiesExt };
