import React from "react";
import GraphqlApiExplorer from "../components/GraphqlApiExplorer";
import { Helmet } from "react-helmet";
import Favicon from "../static/favicon.ico";

const explorer = params => (
    <div className="doc-container">
        <Helmet
            title="Documentation"
            link={[
                {
                    rel: "shortcut icon",
                    type: "image/ico",
                    href: `${
                        params.pageContext.favicon
                            ? params.pageContext.favicon
                            : Favicon
                    }`
                }
            ]}
            script={params.pageContext.scripts || []}
            htmlAttributes={{
                lang: "en"
            }}
        />
        <GraphqlApiExplorer
            data-testid="graphql-api-explorer"
            serviceName={params.pageContext.serviceName}
            apiUrl={params.pageContext.apiUrl}
            examples={params.pageContext.examples}
            logo={params.pageContext.logo}
        />
    </div>
);
export default explorer;
