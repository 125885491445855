import React from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownItem } from "@homeaway/react-dropdown";
import ToolTip from "@homeaway/react-tooltip";

function ToolbarDropDownItem({ text, onClick, disabled, index }) {
    const classnames = `${disabled ? "Dropdown__menu-item--disabled" : ""}`;
    const handleClick = () => {
        if (disabled) {
            return;
        }
        onClick(index);
    };
    return (
        <DropdownItem
            className={classnames}
            link="#"
            key={text}
            text={text}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleClick}
        />
    );
}

ToolbarDropDownItem.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    index: PropTypes.number.isRequired
};

function ToolbarDropdown({ id, label, children, msg }) {
    const dropdown = (
        <Dropdown
            id={id}
            key={id}
            label={label}
            className="GraphqlApiExplorer-Toolbar-Dropdown"
            data-testid="toolbar-dropdown"
        >
            {children}
        </Dropdown>
    );
    return msg ? (
        <ToolTip
            trigger="none"
            isOpen
            placement="bottom"
            content={msg}
            data-testid="toolbar-dropdown-tooltip"
        >
            {dropdown}
        </ToolTip>
    ) : (
        dropdown
    );
}

ToolbarDropdown.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.any,
    msg: PropTypes.string
};

export { ToolbarDropDownItem };
export default ToolbarDropdown;
