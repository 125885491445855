// parameters is for managing the graphql params and updating the query params in the url
const parameters = {};
Object.freeze(parameters.prototype);
function parseForUrlParams(entry) {
    const eq = entry.indexOf("=");
    if (eq > -1) {
        parameters[decodeURIComponent(entry.slice(0, eq))] = decodeURIComponent(
            entry.slice(eq + 1)
        );
    }
    return parameters;
}

function formatVariables(variables) {
    let result = variables;

    if (!variables) {
        return result;
    }

    try {
        result = JSON.stringify(JSON.parse(variables), null, 2);
    } catch (err) {
        // Do nothing, we want to display the invalid JSON as a string, rather
        // than present an error.
    }

    return result;
}

function initializeQueryFromURL() {
    const search = window.location.search;
    search
        .substr(1)
        .split("&")
        .forEach(parseForUrlParams);

    formatVariables(parameters.variables);

    return Object.assign({ ...parameters });
}

function updateURL({ query, variables, operationName }) {
    // update current graphql values
    parameters.query = query === undefined ? parameters.query : query;
    parameters.variables =
        variables === undefined ? parameters.variables : variables;
    parameters.operationName =
        operationName === undefined ? parameters.operationName : operationName;

    const newSearch = `?${Object.keys(parameters)
        .filter(key => Boolean(parameters[key]))
        .map(
            key =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                    parameters[key]
                )}`
        )
        .join("&")}`;
    history.replaceState(null, null, newSearch);
}

export { initializeQueryFromURL, updateURL };
