/* eslint-disable react/no-unknown-property */
/* istanbul ignore file */
// The following files are impacted by an issue with an
// asynchronous call that updates the state.
// See this github issue + discussion.
// https://github.com/testing-library/react-testing-library/issues/281
// Once 16.9 is released these should be revisited
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { buildClientSchema } from "graphql";
import Axios from "axios";
import "./styles.less";
import HeadersEditor from "./../HeadersEditor";
import ToolbarButton from "./../Toolbar/toolbar.button";
import { triggerEdapEvent, eventCategories } from "../../util/edap";
import { useStateCookiesExt } from "./../../util/useStateWithCookies";
import AuthorizeModal from "./../AuthorizeModal";
import { useStaticQuery, graphql } from "gatsby";
// import ToolTip from "@homeaway/react-tooltip";

// Retrieved on initial request for the schema.
// As of today, only the embedded query editor makes post requests
// inside of the documentation. This should be replaced when:
// - The application authenticates users
// - A better pattern for integrating the token with gatsby is determined
function setCrumb(crumb) {
    window.crumb = crumb;
}

async function fetcherFunction(requestParams, graphqlParams) {
    triggerEdapEvent("generic.event", {
        eventcategory: eventCategories.GRAPHIQL_TOOL,
        eventaction: "Execute query button clicked"
    });
    try {
        if (graphqlParams.graphqlParams.query.includes("mutation")) {
            return {
                errors: [
                    {
                        msg: "Mutations are not supported in this playground"
                    }
                ]
            };
        }

        const response = await Axios.post(
            `/graphql/${requestParams.apiName}`,
            graphqlParams,
            { headers: requestParams.headers }
        );
        return response.data;
    } catch (err) {
        return {
            errors: [
                {
                    msg: "Unexpected error while attempting to execute query"
                }
            ]
        };
    }
}

// commented out because we aren't using API Explorer, see lines 229-265 for commented out code
/* function buildApiExplorerLink(parameters) {
    const params = Object.keys(parameters)
        .filter(k => !!parameters[k])
        .map(k => {
            const key = encodeURIComponent(k);
            const value = encodeURIComponent(parameters[k]);
            return `${key}=${value}`;
        })
        .join("&");

    return `?${params}`;
} */

// eslint-disable-next-line complexity
function GraphqlPlayground({
    query = "",
    apiName = "",
    enableHeadersEditor,
    storeDataInCookies
}) {
    const [graphqlQuery, setQuery] = useState(query);

    const authTypes = {
        // Use token from headers/or no auth
        DEFAULT: "DEFAULT",
        // Grab creds from vault and retrieve token from auth server
        VAULT: "VAULT",
        // Username&Password
        BASIC: "BASIC"
    };
    // Headers editor
    const [showHeadersEditor, setShowHeadersEditor] = useState(false);
    const [headers, setHeaders] = useStateCookiesExt(
        {},
        "eg-graphql-pg-headers",
        storeDataInCookies
    );

    // set isAuthorized (iauz for security) so component can make determinations based on authorization status
    const [iauz, setIauz] = useStateCookiesExt(
        false,
        "iauz",
        storeDataInCookies
    );

    // build the schema for graphiql
    const [schema, setSchema] = useState(null);
    const [isIntrospectionEnabled, setIntrospectionState] = useState(true);

    async function initializeSchema() {
        try {
            const introspectionResult = await Axios.get(
                `/graphql/${apiName}/schema`,
                { headers }
            );
            setCrumb(introspectionResult.data.crumb);
            if (isIntrospectionEnabled) {
                setSchema(buildClientSchema(introspectionResult.data.data));
            }
        } catch (err) {
            setIauz(false);
        }
    }
    async function initializeHeader() {
        try {
            const tokenResult = await Axios.get(`/graphql/token`);
            if (tokenResult.status === 200) {
                headers.authorization = tokenResult.data;
                setHeaders(headers);
            }
        } catch (err) {
            //
        }
    }
    async function getServerConfig() {
        const { data } = await Axios.get(`/server/settings`);
        setIntrospectionState(data && !data.isIntrospectionDisabled);
    }

    useEffect(() => {
        getServerConfig();
        if (headers.authorization || iauz) {
            initializeSchema();
        }
    }, []);

    // import the graphiql react component
    const [QueryPlayground, setQueryPlayground] = useState(null);
    useEffect(() => {
        import("graphiql").then(component => {
            setQueryPlayground(component);
        });
    }, []);

    const toggleHeaders = () => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.GRAPHIQL_TOOL,
            eventaction: "Header option clicked"
        });
        setShowHeadersEditor(!showHeadersEditor);
    };

    const closeRequestHeadersEditor = () => {
        setShowHeadersEditor(false);

        // If authorization token exists in header set iauz to true else set to false
        if (
            headers[
                Object.keys(headers).find(
                    key => key.toLowerCase() === "authorization"
                )
            ]
        ) {
            setIauz(true);

            // pull schema if not initialized before
            if (schema === null) {
                initializeSchema();
            }
        } else {
            setIauz(false);
        }
    };

    const closeAuthWindow = () => {
        // closeAuthWindow is only fired if a valid Bearer token was returned, so setIauz to true
        setIauz(true);
        // pull schema if not initialized before
        if (schema === null) {
            initializeSchema();
        }
        if (enableHeadersEditor && !headers.authorization) {
            initializeHeader();
        }
    };

    const data = useStaticQuery(
        graphql`
            query {
                site {
                    pathPrefix
                    siteMetadata {
                        authType
                    }
                }
            }
        `
    );

    return (
        <div className="GraphqlQueryPlayground__wrap">
            {QueryPlayground ? (
                <QueryPlayground.default
                    query={graphqlQuery}
                    schema={schema}
                    onEditQuery={setQuery}
                    // eslint-disable-next-line react/jsx-no-bind
                    fetcher={graphqlParams =>
                        fetcherFunction(
                            { apiName, headers },
                            { graphqlParams, crumb: window.crumb }
                        )
                    }
                >
                    <QueryPlayground.default.Toolbar>
                        {/*    {(QueryPlayground &&
                            !data.site.siteMetadata.authType ===
                                authTypes.BASIC &&
                            isIntrospectionEnabled) ||
                        (QueryPlayground && iauz && isIntrospectionEnabled) ? (
                            <a
                                className="nav-api-explorer"
                                target="_blank"
                                href={`${window.location.origin}${
                                    data.site.pathPrefix
                                }/explorers/graphql${buildApiExplorerLink({
                                    query: graphqlQuery
                                })}`}
                                rel="noopener noreferrer"
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => {
                                    triggerEdapEvent("generic.event", {
                                        eventcategory:
                                            eventCategories.GRAPHIQL_TOOL,
                                        eventaction:
                                            "API explorer option clicked"
                                    });
                                }}
                                // eslint-disable-next-line react/jsx-no-literals
                            >
                                API Explorer
                            </a>
                        ) : isIntrospectionEnabled ? (
                            <ToolTip
                                placement="bottom"
                                content="You must be authorized to use the API Explorer"
                            >
                                <span class="nav-api-explorer disabled-link">
                                    API Explorer
                                </span>
                            </ToolTip>
                        ) : null} */}
                        {!!QueryPlayground &&
                        (enableHeadersEditor ||
                            data.site.siteMetadata.authType ===
                                authTypes.DEFAULT) ? (
                            <ToolbarButton
                                label="Headers"
                                handleClick={toggleHeaders}
                            />
                        ) : null}

                        {!!QueryPlayground &&
                        (enableHeadersEditor ||
                            data.site.siteMetadata.authType ===
                                authTypes.DEFAULT) &&
                        showHeadersEditor ? (
                            <HeadersEditor
                                headers={headers}
                                onUpdate={setHeaders}
                                onClose={closeRequestHeadersEditor}
                            />
                        ) : null}

                        {!!QueryPlayground &&
                        data.site.siteMetadata.authType === authTypes.BASIC ? (
                            <AuthorizeModal
                                label={iauz ? "Authorized" : "Authorize"}
                                onUpdate={setHeaders}
                                onClose={closeAuthWindow}
                            />
                        ) : null}
                    </QueryPlayground.default.Toolbar>
                </QueryPlayground.default>
            ) : (
                <p>Playground temporarily unavailable</p>
            )}
        </div>
    );
}

GraphqlPlayground.propTypes = {
    query: PropTypes.string.isRequired,
    apiName: PropTypes.string.isRequired,
    enableHeadersEditor: PropTypes.bool,
    enableAuthByCreds: PropTypes.bool,
    storeDataInlocalStorage: PropTypes.bool,
    prefixUrl: PropTypes.string,
    storeDataInCookies: PropTypes.bool
};

GraphqlPlayground.defaultProps = {
    enableHeadersEditor: false,
    enableAuthByCreds: false,
    storeDataInCookies: true
};

export default GraphqlPlayground;
