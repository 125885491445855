import { printSchema, parse, print } from "graphql";

function formatQuery(query) {
    try {
        return print(parse(query));
    } catch (err) {
        return query;
    }
}

export default {
    formatQuery,
    printSchema
};
