import CodeStarterUtilities from "./CodeStarterUtilities";
import CurlExportUtilities from "./CurlExportUtilities";
import GraphqlUtilties from "./GraphqlUtilities";
import copy from "copy-to-clipboard";

export default {
    ...CodeStarterUtilities,
    ...CurlExportUtilities,
    ...GraphqlUtilties,
    copyToClipboard: copy
};
