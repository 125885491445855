/* eslint-disable no-useless-escape */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-key */
import React from "react";
import PropTypes from "prop-types";
import "./styles.less";
import { useStaticQuery, graphql } from "gatsby";

export const ChangeLogTable = ({ changelogFile }) => {
    let itemsCount = 0;
    const versions =
        changelogFile && changelogFile.fields
            ? changelogFile.fields.changeLogJson.versions
            : null;

    const changelogProperties = [
        "Added",
        "Changed",
        "Deprecated",
        "Removed",
        "Fixed",
        "Security"
    ];

    return (
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Change</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                {versions
                    ? versions.map(item => {
                          const title = item.title;
                          let result = [];

                          changelogProperties.forEach(changeType => {
                              if (
                                  item.parsed &&
                                  item.parsed[changeType] &&
                                  item.parsed[changeType].length > 0
                              ) {
                                  result.push(
                                      item.parsed[changeType].map(
                                          changedItem => {
                                              itemsCount++;
                                              return (
                                                  <tr key={itemsCount}>
                                                      <td>{itemsCount}</td>
                                                      <td>{title}</td>
                                                      <td>{changeType}</td>
                                                      <td>{changedItem}</td>
                                                  </tr>
                                              );
                                          }
                                      )
                                  );
                              }
                          });

                          return result;
                      })
                    : null}
            </tbody>
        </table>
    );
};
function Changelog(props) {
    // TDOD: changelog paging
    const data = useStaticQuery(
        graphql`
            query MyQuery {
                allMdx(filter: { fileAbsolutePath: { regex: "/CHANGELOG/" } }) {
                    nodes {
                        fields {
                            changeLogJson {
                                versions {
                                    parsed {
                                        Added
                                        Changed
                                        Deprecated
                                        Fixed
                                        Removed
                                        Security
                                    }
                                    title(formatString: "MMMM DD, YYYY")
                                }
                            }
                        }
                        fileAbsolutePath
                    }
                }
            }
        `
    );

    const changelogFile = data.allMdx.nodes.filter(obj => {
        return (
            obj.fileAbsolutePath.replace(/^.*[\\\/]/, "") === props.fileSource
        );
    })[0];
    return <ChangeLogTable changelogFile={changelogFile} />;
}

ChangeLogTable.propTypes = {
    changelogFile: PropTypes.object
};
Changelog.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object
    ]),
    fileSource: PropTypes.string
};

export default Changelog;
