const defaultCurlHeaders = {
    "Accept-Encoding": "gzip, deflate, br",
    Accept: "application/json"
};

function newCurlHeaderString({ key, value }) {
    return `-H '${key}: ${value}'`;
}

function buildCurlHeadersString(requestHeadersMap) {
    return Object.keys(requestHeadersMap)
        .reduce((acc, key) => {
            const requestHeader = {
                key,
                value: requestHeadersMap[key]
            };
            return `${acc}${newCurlHeaderString(requestHeader)} `;
        }, "")
        .trim();
}

function newCurlCommand({ uri, headers = {} }, { query, variables }) {
    const curlRequestHeaders = Object.assign(
        { ...defaultCurlHeaders },
        headers
    );
    const curlHeaders = buildCurlHeadersString(curlRequestHeaders);
    const curlRequestData = JSON.stringify({ query, variables });

    return `curl '${uri}' ${curlHeaders} --data-binary '${curlRequestData}' --compressed`;
}

export default {
    newCurlCommand
};
