/* istanbul ignore file */
// The following files are impacted by an issue with an
// asynchronous call that updates the state.
// See this github issue + discussion.
// https://github.com/testing-library/react-testing-library/issues/281
// Once 16.9 is released these should be revisited
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { buildClientSchema } from "graphql";
import HeadersEditor from "../HeadersEditor";
import GraphqlServices from "./services";
import "./GraphqlApiExplorer.less";
import Tooltip from "@homeaway/react-tooltip";
import Axios from "axios";
import Logo from "./../../static/eg-icon.png";
import { ProgressRadial } from "@homeaway/react-progress";
import ToolbarButton from "../Toolbar/toolbar.button";
import ToolbarDropdown, {
    ToolbarDropDownItem
} from "../Toolbar/toolbar.dropdown";
import { AlertDanger } from "@homeaway/react-alerts";
import { updateURL, initializeQueryFromURL } from "./graphqlparams.url";
import { triggerEdapEvent, eventCategories } from "../../util/edap";
import { useStateCookiesExt } from "./../../util/useStateWithCookies";

function setCrumb(crumb) {
    window.crumb = crumb;
}

async function fetcherFunction(requestParams, graphqlParams) {
    triggerEdapEvent("generic.event", {
        eventcategory: eventCategories.GRAPHIQL_TOOL,
        eventaction: "Execute query button clicked"
    });
    try {
        const response = await Axios.post(
            `/graphql/${requestParams.serviceName}`,
            graphqlParams,
            { headers: requestParams.headers }
        );
        return response.data;
    } catch (err) {
        return {
            errors: [
                {
                    code: "500",
                    message: "Unable to perform request at this time."
                }
            ]
        };
    }
}

function onEditQuery(newGraphqlQuery) {
    updateURL({ query: newGraphqlQuery });
}

function onEditVariables(newGraphqlVariables) {
    updateURL({ variables: newGraphqlVariables });
}

function onEditOperationName(newGraphqlOperationName) {
    updateURL({ operationName: newGraphqlOperationName });
}

function CopyMenu({ showCopiedMsg, children }) {
    return showCopiedMsg ? (
        <Tooltip trigger="none" placement="bottom" isOpen content="copied!">
            {children}
        </Tooltip>
    ) : (
        children
    );
}
CopyMenu.propTypes = {
    showCopiedMsg: PropTypes.bool,
    children: PropTypes.array
};

async function setup({
    serviceName,
    setQuery,
    setVariables,
    setOperationName,
    setLoadingProgress,
    setSchema,
    setExplorer,
    setLoading,
    setHeaders,
    headers
}) {
    const component = await import("graphiql");

    // Initialize the GraphQL params from the query
    const parameters = initializeQueryFromURL();
    setQuery(parameters.query || "");
    setVariables(parameters.variables);
    setOperationName(parameters.operationName);

    // Update the progress to show the user progress
    // as we build the schema
    const introspectionResult = await Axios.get(
        `/graphql/${serviceName}/schema`,
        { headers: headers }
    );
    if (!introspectionResult.data.data === null) {
        setLoading(false);
        setLoadingProgress(100);
    }

    if (!headers.authorization) {
        const tokenResult = await Axios.get(`/graphql/token`);
        if (tokenResult.status == 200) {
            headers.authorization = tokenResult.data;
            setHeaders(headers);
        }
    }

    setLoadingProgress(34);
    setSchema(buildClientSchema(introspectionResult.data.data));
    setCrumb(introspectionResult.data.crumb);
    setLoadingProgress(75);

    // Add a delay so the user actually sees the progress
    // updated
    window.setTimeout(() => {
        setLoadingProgress(100);
        setExplorer(component);

        window.setTimeout(() => {
            setLoading(false);
        }, 1100);
    }, 1100);
}

// eslint-disable-next-line complexity
function GraphqlApiExplorer({ serviceName, apiUrl, examples = [], logo }) {
    const [loading, setLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [Explorer, setExplorer] = useState(null);

    // Graphql Params
    const [query, setQuery] = useState("");
    const [variables, setVariables] = useState({});
    const [operationName, setOperationName] = useState({});
    const [response, setResponse] = useState("");

    // API Schema
    const [schema, setSchema] = useState(null);

    // Graphiql Hooks + Action Buttons
    const [showHeadersEditor, setShowHeadersEditor] = useState(false);
    const [headers, setHeaders] = useStateCookiesExt(
        {},
        "eg-graphql-pg-headers",
        true
    );
    const setExample = index => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.GRAPHIQL_TOOL,
            eventaction: `Request example - ${index} clicked`
        });
        const { query, response } = examples[index];
        setQuery(query);
        setResponse(JSON.stringify(response, null, 2));
    };
    const [showCopiedMsg, setShowCopiedMsg] = useState(false);

    useEffect(() => {
        setup({
            serviceName,
            setQuery,
            setVariables,
            setOperationName,
            setLoadingProgress,
            setSchema,
            setExplorer,
            setLoading,
            setHeaders,
            headers
        });
        // triggerEdapPageView('GraphqlApiExplorer');
    }, []);
    const formatQuery = () => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.GRAPHIQL_TOOL,
            eventaction: "Format query option clicked"
        });
        setQuery(GraphqlServices.formatQuery(query));
    };
    const toggleHeaders = () => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.GRAPHIQL_TOOL,
            eventaction: "Header option clicked"
        });
        setShowHeadersEditor(!showHeadersEditor);
    };
    const onCurlCopyClick = () => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.GRAPHIQL_TOOL,
            eventaction: "Copy curl option clicked"
        });
        const requestOptions = { uri: apiUrl, headers };
        const graphqlParams = {
            query,
            variables,
            operationName
        };
        const newCurlCmd = GraphqlServices.newCurlCommand(
            requestOptions,
            graphqlParams
        );
        GraphqlServices.copyToClipboard(newCurlCmd);
        setShowCopiedMsg(true);
        window.setTimeout(() => {
            setShowCopiedMsg(false);
        }, 1800);
    };

    const copyStarterJS = () => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.GRAPHIQL_TOOL,
            eventaction: "Copy starter JS option clicked"
        });
        const requestOptions = { uri: apiUrl, headers };
        const graphqlParams = { query };
        const snippet = GraphqlServices.newClientStarterJS(
            requestOptions,
            graphqlParams
        );
        GraphqlServices.copyToClipboard(snippet);
        setShowCopiedMsg(true);
        window.setTimeout(() => {
            setShowCopiedMsg(false);
        }, 1800);
    };

    const copySchema = () => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.GRAPHIQL_TOOL,
            eventaction: "Copy schema option clicked"
        });
        GraphqlServices.copyToClipboard(GraphqlServices.printSchema(schema));
        setShowCopiedMsg(true);
        window.setTimeout(() => {
            setShowCopiedMsg(false);
        }, 1800);
    };

    const closeRequestHeadersEditor = () => setShowHeadersEditor(false);
    return (
        <div className="GraphqlApiExplorer">
            <div className="GraphqlApiExplorer__query-editor">
                {loading ? (
                    <ProgressRadial
                        progress={loadingProgress}
                        label="Complete"
                    />
                ) : null}
                {!loading && Explorer ? (
                    <Explorer.default
                        fetcher={graphqlParams =>
                            fetcherFunction(
                                { serviceName, headers },
                                { graphqlParams, crumb: window.crumb }
                            )
                        }
                        onToggleDocs={isOpen => {
                            triggerEdapEvent("generic.event", {
                                eventcategory: eventCategories.GRAPHIQL_TOOL,
                                eventaction: `Docs ${
                                    isOpen ? "opened" : "closed"
                                }`
                            });
                        }}
                        query={query}
                        response={response}
                        variables={variables}
                        operationName={operationName}
                        schema={schema}
                        onEditQuery={newQuery => {
                            onEditQuery(newQuery);
                            setQuery(newQuery);
                            if (response) {
                                setResponse("");
                            }
                        }}
                        onEditVariables={onEditVariables}
                        onEditOperationName={onEditOperationName}
                    >
                        <Explorer.default.Logo>
                            <img
                                style={{ width: logo ? logo.width : "40px" }}
                                src={logo ? logo.url : Logo}
                                alt={logo ? logo.description : "logo"}
                            />
                        </Explorer.default.Logo>
                        <Explorer.default.Toolbar>
                            <div className="GraphqlApiExplorer-Toolbar">
                                <ToolbarButton
                                    label="Format"
                                    handleClick={formatQuery}
                                />
                                <ToolbarButton
                                    label="Headers"
                                    handleClick={toggleHeaders}
                                />
                                <ToolbarDropdown
                                    id="api-explorer-examples-dropdown"
                                    label="Examples"
                                >
                                    {examples.map((example, index) => (
                                        <ToolbarDropDownItem
                                            key={example.text}
                                            text={example.text}
                                            onClick={setExample}
                                            index={index}
                                        />
                                    ))}
                                </ToolbarDropdown>
                                <CopyMenu showCopiedMsg={showCopiedMsg}>
                                    <ToolbarDropdown
                                        id="graphql-api-explorer-copy-dropdown"
                                        label="Copy"
                                    >
                                        <ToolbarDropDownItem
                                            key="ssdi-cURL"
                                            text="cURL"
                                            onClick={onCurlCopyClick}
                                        />
                                        <ToolbarDropDownItem
                                            key="ssdi-js-client-starter"
                                            text="JS Client Starter"
                                            onClick={copyStarterJS}
                                        />
                                        <ToolbarDropDownItem
                                            key="ssdi-copy-schema"
                                            text="Schema"
                                            onClick={copySchema}
                                        />
                                    </ToolbarDropdown>
                                </CopyMenu>
                            </div>
                        </Explorer.default.Toolbar>
                    </Explorer.default>
                ) : null}
                {!loading && !Explorer ? (
                    <AlertDanger
                        title="GraphQL Playground"
                        msg="Playground temporarily unavailable"
                    />
                ) : null}
            </div>
            {!!Explorer && showHeadersEditor ? (
                <HeadersEditor
                    headers={headers}
                    onUpdate={setHeaders}
                    onClose={closeRequestHeadersEditor}
                />
            ) : null}
        </div>
    );
}

GraphqlApiExplorer.propTypes = {
    serviceName: PropTypes.string.isRequired,
    apiUrl: PropTypes.string.isRequired,
    examples: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            query: PropTypes.string.isRequired,
            response: PropTypes.object
        })
    ),
    requestOptions: PropTypes.shape({
        headers: PropTypes.object
    }),
    logo: PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
        width: PropTypes.string
    })
};

export default GraphqlApiExplorer;
