import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.less";

function NestedMenu(props) {
    const { menuLinks } = props;

    const [currentActiveLink, updateCurrentActiveLink] = useState("");

    function handleSlideDown(selectedLinkName) {
        const arrow = document.getElementById(`right_${selectedLinkName}`);
        const wrapper = document.getElementById(
            `sub_links_${selectedLinkName}`
        );
        if (arrow.classList.contains("right_inactive")) {
            // Slide Down
            wrapper.style.height = `${wrapper.scrollHeight}px`;

            wrapper.classList.replace("inactive", "active");
            arrow.classList.replace("right_inactive", "right_active");
        } else {
            // Slide up
            wrapper.style.height = "0";

            arrow.classList.replace("right_active", "right_inactive");
            wrapper.classList.replace("active", "inactive");
        }
    }

    function handleSubLinkClick(selectedLinkName, currentActiveLink) {
        const selectedLink = document.getElementById(
            `internal_link_${selectedLinkName}`
        );
        if (currentActiveLink === `internal_link_${selectedLinkName}`) {
            return currentActiveLink;
        }
        const lastActive = document.getElementById(currentActiveLink);
        if (
            selectedLink.classList.contains(
                "nested_menu_base__internal_links_inactive"
            )
        ) {
            selectedLink.classList.replace(
                "nested_menu_base__internal_links_inactive",
                "nested_menu_base__internal_links_active"
            );
            if (lastActive) {
                lastActive.classList.replace(
                    "nested_menu_base__internal_links_active",
                    "nested_menu_base__internal_links_inactive"
                );
            }
        }
        updateCurrentActiveLink(`internal_link_${selectedLinkName}`);
    }

    return (
        <div className="nested_menu_div">
            <ul className="nested_menu_base" key={"main_list"}>
                {menuLinks.map(link => (
                    <li
                        className="nested_menu_base__list_item"
                        key={link.label}
                    >
                        {/*Main Link - Arrow Button */}
                        {/* MAKE THIS DIV CLICKABLE TO OPEN SUB MENU */}
                        <div
                            id={`main_links_${link.label}`}
                            className="nested_menu_base__main_links"
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onKeyDown={() => {}}
                            role={"button"}
                            tabIndex="0"
                            onClick={() => handleSlideDown(link.label)}
                            key={`main_links_${link.label}`}
                            data-testid={"main_links"}
                        >
                            <div
                                className="nested_menu_base__main_links_div"
                                key={`nested_menu_base__main_links_div_${link.label}`}
                            >
                                <a
                                    className="nested_menu_base__links"
                                    href={link.href}
                                    key={`nested_menu_base__links_${link.label}`}
                                >
                                    <b key={link.label}>{link.label}</b>
                                </a>
                            </div>

                            <div
                                className="nested_menu_base__main_button_div"
                                key={`nested_menu_base__main_button_div_${link.label}`}
                            >
                                <i
                                    id={`right_${link.label}`}
                                    className="arrow right_inactive"
                                    key={`right_${link.label}`}
                                    data-testid={"arrow"}
                                ></i>
                            </div>
                        </div>
                        {/*Main Link - Arrow Button */}

                        {/* Blue Bar - Sub Menu */}
                        {/* DIV BELOW IS THE WRAPPER FOR SLIDING UP AND DOWN*/}
                        <div
                            className="nested_menu_base__sub_links inactive"
                            id={`sub_links_${link.label}`}
                            key={`nested_menu_base__sub_links_${link.label}`}
                            data-testid={"sub_menu"}
                        >
                            {link.children && link.children.length > 0 ? (
                                <ul
                                    className="nested_menu_base__internal_ul"
                                    aria-label="submenu"
                                    key={`nested_menu_base__internal_ul_${link.label}`}
                                >
                                    {link.children.map(subLink => (
                                        <li
                                            className="nested_menu_base__internal_list_item"
                                            key={`"nested_menu_base__internal_list_item${subLink.label}`}
                                        >
                                            <div
                                                className="nested_menu_base__internal_links_div"
                                                key={`nested_menu_base__internal_links_div_${link.label}`}
                                            >
                                                <div
                                                    id={`active_view_line_${subLink.label}`}
                                                    className="active_view_line"
                                                    key={`active_view_line_${link.label}`}
                                                ></div>
                                                <a
                                                    id={`internal_link_${subLink.label}`}
                                                    className="nested_menu_base__internal_links_inactive"
                                                    href={subLink.href}
                                                    onClick={() => {
                                                        handleSubLinkClick(
                                                            subLink.label,
                                                            currentActiveLink
                                                        );
                                                    }}
                                                    key={`internal_link_${link.label}`}
                                                    data-testid={`sub_link_${subLink.label}`}
                                                >
                                                    {subLink.label}
                                                </a>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : null}
                        </div>
                        {/* Blue Bar - Sub Menu */}
                    </li>
                ))}
            </ul>
        </div>
    );
}

NestedMenu.propTypes = {
    menuLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            href: PropTypes.string,
            children: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string,
                    href: PropTypes.string
                })
            )
        })
    )
};

export default NestedMenu;
