import React, { useState } from "react";
import PropTypes from "prop-types";
import copy from "copy-to-clipboard";
import { triggerEdapEvent, eventCategories } from "../../util/edap";
import "./styles.less";

function getChildContent(children) {
    if (Array.isArray(children)) {
        return children[0].props.children;
    }
    return children.props.children;
}

function formatClassName(className) {
    return `exampleResponse ${className.split(" ")[0]}`;
}

function contentFromString(mdxChildren) {
    return mdxChildren;
}

function contentFromObject(mdxChildren) {
    return mdxChildren.reduce((acc, child) => {
        if (typeof child === "object") {
            acc = acc + child.props.children;
        } else if (typeof child === "string") {
            acc = acc + child;
        }
        return acc;
    }, "");
}

function typeIs(target) {
    let type = "";
    if (Array.isArray(target)) {
        type = "array";
    } else {
        type = typeof target;
    }

    return type;
}
const supportedMdxChildren = {
    string: contentFromString,
    array: contentFromObject
};

function buildCopyContent(mdxChildren) {
    const handler = supportedMdxChildren[typeIs(mdxChildren)];
    if (!handler) {
        throw new Error("unsupported MDX children type");
    }

    return handler(mdxChildren);
}

function CopyCodeButton(props) {
    const defaultTxt = "Copy";
    const copiedTxt = "Copied";
    const [text, setBtnText] = useState(defaultTxt);
    const [copiedTimeout, setCopiedTimeout] = useState(null);
    const handleClick = () => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.COPY_CODE,
            eventaction: "Copy code button clicked"
        });
        copy(props.content);
        setBtnText(copiedTxt);
        if (copiedTimeout) {
            window.clearTimeout(copiedTimeout);
        }
        const newCopiedTimeout = window.setTimeout(() => {
            setBtnText(defaultTxt);
        }, 2000);
        setCopiedTimeout(newCopiedTimeout);
    };

    return (
        <button
            className="copy-button"
            onClick={handleClick}
            data-testid="copy-btn"
        >
            {text}
        </button>
    );
}

CopyCodeButton.propTypes = {
    content: PropTypes.string
};

function Pre(props) {
    const { className, children } = props;

    let codeContent = getChildContent(children);

    return (
        <pre className={formatClassName(className)}>
            {codeContent}
            {className.indexOf("-example") > -1 ? null : (
                <CopyCodeButton content={buildCopyContent(codeContent)} />
            )}
        </pre>
    );
}

Pre.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object
    ]),
    className: PropTypes.string
};

export default Pre;
