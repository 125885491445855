import PropTypes from "prop-types";

export default {
    NavLinks: PropTypes.shape({
        label: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        node: PropTypes.node,
        disabled: PropTypes.bool
    })
};
