import React from "react";
import PropTypes from "prop-types";
import "./styles.less";
import { SVGIcon } from "@homeaway/react-svg";
import { LINK__24 } from "@homeaway/svg-defs";

/**
 * The HTag is used to customize the header elements
 * primarily <h> tags in MDX files. This component is intended
 * for use with MDX and provided via an MDXProvider
 */
function HTag(props) {
    const { children, id } = props;
    const [elem, title] = children;
    const { parentName: Element } = elem.props;

    return (
        <>
            <span className="anchor-scroll" id={id}></span>
            <Element className="section-title anchor-hide">
                <SVGIcon className="anchor anchor-link" markup={LINK__24} />
                <a href={`#${id}`}>{title}</a>
            </Element>
        </>
    );
}

HTag.propTypes = {
    children: PropTypes.array,
    id: PropTypes.string
};
export default HTag;
