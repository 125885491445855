const jsClientDependencyInstructions = `// Run the following command to install dependencies:
//  npm install apollo-boost react-apollo graphql --save
// Links:
// - https://www.apollographql.com/docs/react/essentials/get-started`;

const jsClientCodeDependencies = `import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';`;

function getOperationContext(headers) {
    if (typeof headers !== "object") {
        return "";
    }

    return `operation.setContext({ headers: ${JSON.stringify(headers)} })`;
}

function jsClientCodeStarter({ uri = "", options = {} }, { query = "" }) {
    const { headers, ...fetchOptions } = options;
    return `const client = new ApolloClient({
    uri: '${uri}',
    fetchOptions: ${JSON.stringify(fetchOptions)},
    request: async (operation) => {
        ${getOperationContext(headers)}
    });
  },
})

client
  .query({
    query: gql\`\n${query}\`
  })
  .then(result => console.log(result));
`;
}

function newClientStarterJS(requestOptions, graphqlParams) {
    return `${jsClientDependencyInstructions}
${jsClientCodeDependencies}
${jsClientCodeStarter(requestOptions, graphqlParams)}
`;
}

export default {
    newClientStarterJS
};
