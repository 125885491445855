import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigation } from "@homeaway/react-navigation";
import NavigationProperties from "../NavigationPropTypes";
import { triggerEdapEvent, eventCategories } from "../../../util/edap";
import "./styles.less";

function SecondaryNavigation(props) {
    const { navLinks, activeIndex = 0 } = props;

    // Set active index for navigation
    const [activeNavIndex, setActiveNavIndex] = useState(activeIndex);

    const updateActiveNavIndex = (_event, newActiveSubheaderIndex) => {
        setActiveNavIndex(newActiveSubheaderIndex);
    };

    const handleNavLinkClick = (e, newActiveSubheaderIndex) => {
        triggerEdapEvent("generic.event", {
            eventcategory: eventCategories.SECONDARY_NAVIGATION,
            eventaction: `${navLinks[newActiveSubheaderIndex].label} link clicked`
        });
        updateActiveNavIndex(e, newActiveSubheaderIndex);
    };

    return (
        <div className="secondary-navigation">
            <nav
                className="navbar navbar-default navbar-static-top docs-sub-nav-bar-styles"
                role="navigation"
            >
                <div className="container-fluid">
                    <Navigation
                        noMobileSelect={true}
                        activeIndex={activeNavIndex}
                        links={navLinks}
                        onLinkClick={handleNavLinkClick}
                        data-testid="secondary-navigation-links"
                    />
                </div>
            </nav>
        </div>
    );
}

SecondaryNavigation.propTypes = {
    activeIndex: PropTypes.number,
    navLinks: PropTypes.arrayOf(NavigationProperties.NavLinks).isRequired
};

export default SecondaryNavigation;
