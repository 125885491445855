import HTag from "../HTag";
import Link from "../Link";
import Pre from "../Pre";

// Extend/Ovverride this module to add more components
const apiGuideComponents = {
    // GraphqlPlayground,
    // RestApiExplorer,
    h1: HTag,
    h2: HTag,
    h3: HTag,
    h4: HTag,
    h5: HTag,
    h6: HTag,
    a: Link,
    pre: Pre
};

export default apiGuideComponents;
