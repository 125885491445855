import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import DocLayout from "../Layout/docs";
import PrimaryNavigation from "./PrimaryNavigation";
import SecondaryNavigation from "./SecondaryNavigation";
import NestedMenu from "./NestedSideMenu";
import { determineActiveSection } from "./content.active-section";
import Favicon from "./../../static/favicon.ico";
import "./styles.less";
import "./footer.less";
import apiGuideComponents from "./apiGuideComponents";

export const query = graphql`
    query($slug: String!) {
        navigation: allMdx {
            navigationLinks: nodes {
                frontmatter {
                    secondary
                    primary
                    prefix
                    slug
                    pageOrder
                    disabled
                }
            }
        }

        doc: mdx(fileAbsolutePath: { regex: $slug }) {
            tableOfContents
            frontmatter {
                title
                domain
                prefix
                slug
                primary
                secondary
            }
            body
        }

        file(absolutePath: { regex: $slug }) {
            modifiedTime(formatString: "MMMM DD, YYYY")
        }

        site {
            pathPrefix
        }
    }
`;

function recurseChildrenOfItemLinks(items) {
    if (!items) {
        return [];
    }
    return items.map(({ url, title, items }) => {
        return {
            label: title,
            href: url,
            children: recurseChildrenOfItemLinks(items)
        };
    });
}

function Guide(props) {
    const { data } = props;
    const { navigation, doc, file, site } = data;
    const hasLinks =
        doc &&
        doc.tableOfContents &&
        doc.tableOfContents.items &&
        doc.tableOfContents.items.length > 0;
    const links = hasLinks
        ? doc.tableOfContents.items.map(({ url, title, items }) => {
              return {
                  label: title,
                  href: url,
                  children: recurseChildrenOfItemLinks(items)
              };
          })
        : [];

    // eslint-disable-next-line no-unused-vars
    const [activeContentItem, updateActiveContentItem] = useState(0);

    function handleBackToTop() {
        const sections = Array.from(
            document.querySelectorAll("h2.section-title")
        );
        updateActiveContentItem(determineActiveSection(sections));
        if (window !== undefined) {
            window.scrollTo({
                left: 0,
                top: 0,
                behavior: "smooth"
            });
            const scrollTopButton = document.getElementById("scroll-top");
            scrollTopButton.style.display = "none";
        }
    }

    useEffect(() => {
        const sections = Array.from(
            document.querySelectorAll("h2.section-title")
        );
        const handleScroll = () => {
            updateActiveContentItem(determineActiveSection(sections));
            const scrollTopButton = document.getElementById("scroll-top");
            if (
                activeContentItem > 10 ||
                document.documentElement.scrollTop > 10
            ) {
                scrollTopButton.style.display = "block";
            } else {
                scrollTopButton.style.display = "none";
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const primaryNavLinks = navigation.navigationLinks
        .sort((a, b) => a.frontmatter.pageOrder - b.frontmatter.pageOrder)
        .filter((obj, pos, arr) => {
            return (
                arr
                    .map(mapObj => mapObj.frontmatter.prefix)
                    .indexOf(obj.frontmatter.prefix) === pos
            );
        })
        .map(obj => {
            return {
                label: obj.frontmatter.primary,
                node: (
                    <Link
                        to={
                            site.pathPrefix +
                            "/" +
                            obj.frontmatter.prefix +
                            "/" +
                            obj.frontmatter.slug
                        }
                    >
                        {obj.frontmatter.primary}
                    </Link>
                )
            };
        });

    if (props.pageContext.homebtn.name && props.pageContext.homebtn.url) {
        // Navigation back to the portal
        primaryNavLinks.push({
            label: props.pageContext.homebtn.name,
            href: props.pageContext.homebtn.url,
            node: (
                <Link to={props.pageContext.homebtn.url}>
                    {props.pageContext.homebtn.name}
                </Link>
            )
        });
    }

    const secondaryNavLinks = navigation.navigationLinks
        .filter(a => a.frontmatter.prefix === doc.frontmatter.prefix)
        .filter(a => a.frontmatter.disabled !== true)
        .sort((a, b) => a.frontmatter.pageOrder - b.frontmatter.pageOrder)
        .map(obj => {
            return {
                label: obj.frontmatter.secondary,
                node: (
                    <Link
                        to={
                            site.pathPrefix +
                            "/" +
                            doc.frontmatter.prefix +
                            "/" +
                            obj.frontmatter.slug
                        }
                    >
                        {obj.frontmatter.secondary}
                    </Link>
                )
            };
        });

    const currentSecondaryIndex = secondaryNavLinks.findIndex(
        e => e.label === doc.frontmatter.secondary
    );
    const currentPrimaryIndex = primaryNavLinks.findIndex(
        e => e.label === doc.frontmatter.primary
    );

    return (
        <div className="doc-container">
            <Helmet
                title="Documentation"
                link={[
                    {
                        rel: "shortcut icon",
                        type: "image/ico",
                        href: `${
                            props.pageContext.favicon
                                ? props.pageContext.favicon
                                : Favicon
                        }`
                    }
                ]}
                script={props.pageContext.scripts || []}
                htmlAttributes={{
                    lang: "en"
                }}
            />
            <div className="documentation-nav-header">
                <PrimaryNavigation
                    navLinks={primaryNavLinks}
                    logo={props.pageContext.logo}
                    activeIndex={currentPrimaryIndex}
                />
                <SecondaryNavigation
                    navLinks={secondaryNavLinks}
                    activeIndex={currentSecondaryIndex}
                />
            </div>
            <div className="content-wrapper">
                <div className="content-container">
                    {hasLinks ? (
                        <div className="content-navigation">
                            <div className="side-nav sticky">
                                <p className="content-nav-title">Content</p>
                                <NestedMenu menuLinks={links} />
                            </div>
                        </div>
                    ) : null}

                    <div
                        className="content-area"
                        data-testid="documentation-content"
                    >
                        <DocLayout>
                            <h1>{doc.frontmatter.title}</h1>
                            <MDXProvider components={apiGuideComponents}>
                                <MDXRenderer>{doc.body}</MDXRenderer>
                            </MDXProvider>
                            <div
                                className="footer"
                                data-testid="documentation-footer"
                            >
                                © {new Date().getFullYear()} Expedia, Inc. All
                                rights reserved. Last updated&nbsp;
                                {file.modifiedTime}
                            </div>
                        </DocLayout>
                    </div>
                </div>
            </div>
            <div className="scroll-to-top">
                <button
                    className="scroll-to-top-button"
                    id="scroll-top"
                    data-testid="scroll-top"
                    onClick={() => {
                        handleBackToTop();
                    }}
                >
                    To Top
                </button>
            </div>
        </div>
    );
}

Guide.propTypes = {
    data: PropTypes.shape({
        navigation: PropTypes.shape({
            navigationLinks: PropTypes.arrayOf(
                PropTypes.shape({
                    frontmatter: PropTypes.shape({
                        secondary: PropTypes.string,
                        slug: PropTypes.string,
                        primary: PropTypes.string,
                        prefix: PropTypes.string,
                        pageOrder: PropTypes.string,
                        disabled: PropTypes.bool
                    })
                })
            )
        }),
        file: PropTypes.shape({
            modifiedTime: PropTypes.string
        }),
        doc: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                domain: PropTypes.string,
                prefix: PropTypes.string,
                slug: PropTypes.string,
                primary: PropTypes.string,
                secondary: PropTypes.string
            }),
            tableOfContents: PropTypes.object,
            body: PropTypes.string
        }),
        site: PropTypes.shape({
            pathPrefix: PropTypes.string
        })
    }),
    pageContext: PropTypes.shape({
        homebtn: PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string
        }),
        logo: PropTypes.shape({
            url: PropTypes.string,
            description: PropTypes.string
        }),
        favicon: PropTypes.string,
        scripts: PropTypes.string
    })
};

export default Guide;
