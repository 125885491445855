import { Modal } from "@homeaway/react-modal";
import React, { Component } from "react";
import { Button } from "@homeaway/react-buttons";
import { FormInput } from "@homeaway/react-form-components";
import Axios from "axios";
import ToolbarButton from "./../Toolbar/toolbar.button";
import { AlertDanger } from "@homeaway/react-alerts";
import PropTypes from "prop-types";

export default class AuthorizeModal extends Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            isOpen: false,
            clientId: "",
            secret: "",
            displayError: false
        };
    }

    handleOpen() {
        this.setState({ isOpen: true });
    }

    handleClose() {
        this.setState({ isOpen: false });
    }

    handleSave = async () => {
        if (this.state.clientId && this.state.secret) {
            this.setState({ displayError: false });
            await Axios.post(`/graphql/token`, {
                clientid: this.state.clientId,
                secret: this.state.secret
            })
                .then(() => {
                    this.setState({ isOpen: false, displayError: false });
                    this.props.onClose();
                })
                .catch(() => {
                    this.setState({ displayError: true });
                });
        }
    };

    handleChange(event) {
        const formInputName = event.target.name;
        this.setState({
            [formInputName]: event.target.value
        });
    }

    render() {
        return (
            <div>
                <ToolbarButton
                    handleClick={this.handleOpen}
                    label={this.props.label}
                />
                <Modal
                    id="authorize-modal-1"
                    isOpen={this.state.isOpen}
                    onClose={this.handleClose}
                    footer={[
                        <Button
                            className="btn btn-default"
                            onClick={this.handleClose}
                            key={1}
                            label="Close"
                        />,
                        <Button
                            className="btn btn-primary"
                            onClick={this.handleSave}
                            key={2}
                            label="Save"
                            data-testid="btn-save"
                        />
                    ]}
                >
                    <h2>Please enter you client id and secret.</h2>
                    <FormInput
                        id="client-id"
                        name="clientId"
                        label="Client Id"
                        className="custom-class"
                        data-testid="client-id"
                        onChange={this.handleChange}
                        value={this.state.clientId}
                    />
                    <FormInput
                        id="secret"
                        name="secret"
                        label="Secret"
                        className="custom-class"
                        data-testid="secret"
                        onChange={this.handleChange}
                        value={this.state.secret}
                    />
                    {this.state.displayError ? (
                        <AlertDanger
                            title="Uh oh!"
                            msg="Client Id or Secret is incorrect"
                        />
                    ) : null}
                </Modal>
            </div>
        );
    }
}

AuthorizeModal.propTypes = {
    onClose: PropTypes.func,
    label: PropTypes.string
};
