import React from "react";
import PropTypes from "prop-types";
import { Button } from "@homeaway/react-buttons";

function ToolbarButton({ label, handleClick, disabled }) {
    return (
        <Button
            size="xs"
            className={`btn btn-${disabled ? "disabled" : "default"}`}
            key={label}
            label={label}
            onClick={handleClick}
            disabled={disabled}
        />
    );
}

ToolbarButton.propTypes = {
    label: PropTypes.string,
    handleClick: PropTypes.func,
    disabled: PropTypes.bool
};

export default ToolbarButton;
