import React from "react";
import PropTypes from "prop-types";
import "./styles.less";

function ExternalLinkIcon(props) {
    return React.createElement(
        "svg",
        props,
        React.createElement(
            "g",
            {
                fill: "none",
                stroke: "#343e44",
                strokeLinecap: "round",
                strokeLinejoin: "round"
            },
            React.createElement("path", {
                d:
                    "M13.7 9.9v3c0 .6-.5 1-1.2 1h-9c-.7 0-1.2-.4-1.2-1v-3M3.9 5.5L8 2l4.1 3.5M8 2.6v7.6"
            })
        )
    );
}

ExternalLinkIcon.defaultProps = {
    "data-id": "SVG_SHARE__16",
    width: "16",
    height: "16",
    viewBox: "0 0 16 16"
};

function Link(props) {
    // eslint-disable-next-line react/prop-types
    const { href, target, children } = props;

    return (
        <>
            {href.indexOf("https://") > -1 ? (
                <a href={href} target="_blank" rel="noopener noreferrer">
                    {children}
                    <span className="externalLink">
                        <ExternalLinkIcon
                            className="inline-icon externalLink--icon"
                            alt="external link"
                            data-testid="external-link-icon"
                        />
                    </span>
                </a>
            ) : (
                <a href={href} target={target}>
                    {children}
                </a>
            )}
        </>
    );
}

Link.propTypes = {
    href: PropTypes.string
};

export default Link;
